import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface IMenuItem {
    index: number;
    text: string;
    icon?: string;
    disabled: boolean;
    open: boolean;
    onClick: (n: any) => any;
}

const MenuItemContainer = styled.div`
    width: 100%;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        // borderRadius: 25,
    },
    text: {
        paddingLeft: '30px',
    },
}));

const StyledListItem = withStyles((theme) => ({
    root: {
        height: '54px',
        '&$selected': {
            backgroundColor: theme.palette.neutral.transparentWhite,
        },
        '&$selected:hover': {
            backgroundColor: theme.palette.neutral.transparentWhite,
        },
    },
    selected: {},
}))(MuiListItem);

const MenuItem = ({
    index,
    text,
    icon,
    disabled,
    open,
    onClick,
}: IMenuItem) => {
    const classes = useStyles();

    return (
        <>
            <MenuItemContainer>
                <StyledListItem
                    className={classes.root}
                    button
                    key={text}
                    disabled={disabled}
                    onClick={onClick}
                    selected={open}
                >
                    <ListItemText
                        className={classes.text}
                        disableTypography
                        primary={
                            <Typography style={{ fontWeight: 'bold' }}>
                                {text}
                            </Typography>
                        }
                    />
                    {index === 3 && <OpenInNewIcon />}
                </StyledListItem>
            </MenuItemContainer>
        </>
    );
};

export default MenuItem;
